/* .testPin{
  position: relative;
  width: 300px;
  height: 300px;
  background-color: #2196F3;
}
.pin{
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  left:150px;
  top: 50px;
} */
.dhx_timeline_label_col{
  background-color: white;
}
.dhx_timeline_label_wrapper{
  background-color: white;
  z-index: 100000;
}
.dhx_timeline_label_row{
  background-color: white;
}
.dhx_lightbox_minical{
  height: 50px;
}
select.dhx_readonly{
  width: 200px;
}
.dhx_lightbox_time_select{
  display: none
}
.timeline_weekend{
  background-color:rgb(236, 236, 236);
}

.bgActive{
  color:#79CA47;
}
.bgNonActive{
  color:white;
}
.App {
  text-align: center;
}
.fa-star{
  color : lightslategray
}
.fa-star.gold {
	color: #ffdc0f;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.docPopup{
  background-color: rgb(37, 40, 58);
  padding: 1vw 1vw;
  border-radius: 20px;
  position: absolute;
  top: -4vw;
  right:2.5vw;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gantt_task_line, .gantt_line_wrapper {
  margin-top: -12px;
}
.gantt_side_content {
  margin-bottom: 7px;
}
.gantt_task_link .gantt_link_arrow {
  margin-top: -12px
}
.gantt_side_content.gantt_right {
  bottom: 0;
}
.baseline {
  position: absolute;
  border-radius: 20px;
  opacity: 0.6;
  margin-top: 17px;
  height: 0vh;
  background: #ffd180;
  border: 1px solid rgb(255,153,0);
}
.gantt_task_line.gantt_split_parent {
  display: none;
}

/* code local */
.activeLocal{
  color: #6a6a6b;
}
.bgRed{
  background-color: red;
  color: red;
}


/* format pdf last planner*/
.pageGardeLastPlanner{
  position: absolute;
  height: 80vh;
  top:105px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hide-format{
  display: none;
}
.page-header, .page-header-space {
  height: 100px;
}

.page-footer, .page-footer-space {
  height: 50px;

}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 94%;
  border-top: 1px solid gainsboro; /* for demo */
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
  border-bottom: 1px solid gainsboro; /* for demo */
}

.page {
  width: 100%;
  page-break-after: always;
}

@page {
  margin: 10mm;
}

@media print {
   thead {display: table-header-group;} 
   tfoot {display: table-footer-group;}
   
   button {display: none;}
   
   body {margin: 0;}
}
.tabulator-row .tabulator-cell{
  overflow: visible !important ;
}
.tabulator{
  overflow: visible !important;
}
.tabulator-tableholder{
overflow-x: scroll !important;
}
