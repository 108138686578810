.contenuRetard{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
    .formRetard{
        width: 50%;
        border: solid  rgba(26, 29, 48, 0.986) 2px ;
        border-radius: 1vw;
        background-color: rgb(252, 252, 252);
        padding-bottom: 2vw;
        .headerGantt{
            background-color: rgba(26, 29, 48, 0.986);
            border: solid  rgba(26, 29, 48, 0.986) 2px;
            font-weight: bold;
            font-style: italic;
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            margin-bottom: 1vw;
            h1{
                color: white;
            }
            a{
                font-size: 1vw;
            }
            a:hover{
                color: #79CA47;
            }
        }
    }
}
.hideLink{
    display: none;
}