.cibleTabulatorCr {
    overflow: scroll !important;

    .tabulator-header {
        overflow: visible !important;
    }

    .tabulator-tableholder {
        overflow: visible !important;
    }
}

.cibleTabulatorCrIntemperie {
    .tabulator-header {
        overflow: visible !important;
    }

    .tabulator-tableholder {
        overflow: visible !important;
    }
}